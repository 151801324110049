import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { hasMatch, score } from 'fzy.js'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { debounce } from 'lodash'
import { AgGridReact } from 'ag-grid-react'
import {
  ModuleRegistry,
  ColumnAutoSizeModule,
  ColumnHoverModule,
  RowStyleModule,
  PaginationModule,
  RowDragModule,
  TextFilterModule,
  ExternalFilterModule,
  QuickFilterModule,
  RowSelectionModule,
  GridStateModule,
  ColumnApiModule,
  RowApiModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  IServerSideDatasource,
  GridReadyEvent,
  CellStyleModule,
  CellClickedEvent,
  ColDef,
  IRowNode,
  DragAndDropModule,
} from 'ag-grid-community'

import {
  SetFilterModule,
  MultiFilterModule,
  CellSelectionModule,
  TreeDataModule,
  ColumnMenuModule,
  ContextMenuModule,
  ServerSideRowModelApiModule,
  LicenseManager,
} from 'ag-grid-enterprise'

import classNames from 'classnames'
import { ChartAxis } from 'src/types/chartTypes'
import { TextInput, Text } from 'src/components/ui'

import PageSizeSelector from './templates/PageSizeSelector'
import { agGridLicenseKey } from './license'
import { Id, TableConfig } from './table.types'
import { getBaseFilterParams, getNestedValue } from './table.utils'
import { tableTheme } from './table.theme'

LicenseManager.setLicenseKey(agGridLicenseKey)

ModuleRegistry.registerModules([
  DragAndDropModule,
  ColumnAutoSizeModule,
  ColumnHoverModule,
  RowStyleModule,
  PaginationModule,
  RowDragModule,
  TextFilterModule,
  ExternalFilterModule,
  QuickFilterModule,
  RowSelectionModule,
  GridStateModule,
  ColumnApiModule,
  RowApiModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  SetFilterModule,
  MultiFilterModule,
  CellSelectionModule,
  TreeDataModule,
  ColumnMenuModule,
  ContextMenuModule,
  ServerSideRowModelApiModule,
  CellStyleModule,
])

const collator = new Intl.Collator()
function comparatorFunction(a: any, b: any): number {
  if (typeof a === 'string' && typeof b === 'string')
    return collator.compare(a, b)
  return a - b
}
interface DynamicTableProps<T extends Id> {
  config: TableConfig<T>
  actions?: {
    selectRow?: (data: any) => void
    setSelectedRows?: (data: any) => void
  }
  selectedRows?: string[]
  selectedScatter?: {
    id: string
    axis: ChartAxis
  }[]
  headerSummary?: string
  modal?: boolean
  maxHeightFull?: boolean
  allowOverflow?: boolean
  id: string
  selectMultipleRows?: boolean
  contextItems?: any[]
  contextItemClick?: (e: any) => void
  rowHeight?: number
  className?: string
  shouldDeselect?: boolean
  getContextItems?: (props: any) => any[]
  // Use callback in parent component to change the function and trigger onFilterChanged
  isExternalFilterPresent?: () => boolean
  doesExternalFilterPass?: (node: any) => boolean
  fuzzySearch?: boolean
  fuzzySearchField?: string[]
  currentPage?: number
  currentPageSize?: number
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
  shouldAutofocusSearch?: boolean
}

interface ClientSideTableProps<T extends Id> extends DynamicTableProps<T> {
  data: T[]
  dataSource?: undefined
  cacheBlockSize?: undefined
}

interface ServerSideTableProps<T extends Id> extends DynamicTableProps<T> {
  data?: T[]
  dataSource: IServerSideDatasource
  cacheBlockSize?: number
}

export function DynamicTable<T extends Id>({
  data,
  config,
  actions,
  selectedRows = [],
  selectedScatter = [],
  headerSummary,
  id,
  selectMultipleRows,
  rowHeight = 38,
  className,
  shouldDeselect,
  getContextItems,
  isExternalFilterPresent,
  doesExternalFilterPass,
  fuzzySearch,
  fuzzySearchField,
  currentPage,
  currentPageSize,
  onPageChange,
  onPageSizeChange,
  shouldAutofocusSearch,
  dataSource,
  cacheBlockSize,
}: ClientSideTableProps<T> | ServerSideTableProps<T>): JSX.Element {
  const [selectedPageSize, setSelectedPageSize] = useState(config.pageSize)
  const [paginationPortal, setPaginationPortal] = useState<any>(null)
  const [searchInput, setSearchInput] = useState('')
  const searchInputRef = useRef('')
  const containerRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)
  const { tablePersistence } = useFlags()

  const {
    columns,
    pageSize,
    pageSizes,
    allowPaging,
    allowSearch = true,
    allowSorting = true,
    fixedColumnMenu = true,
    disallowSelection,
  } = config
  const setPaginationElement = (): void => {
    // On first render, set the page to the current page
    if (currentPage) {
      gridRef.current?.api?.paginationGoToPage(currentPage)
    }
    const paginationPannels = [
      ...document.getElementsByClassName('ag-paging-panel'),
    ]
    const panel = paginationPannels.find(p => containerRef.current?.contains(p))
    if (panel) {
      setPaginationPortal(panel)
    }
  }

  const onGridReady = (params: GridReadyEvent): void => {
    setPaginationElement()
    if (dataSource) {
      params.api.setGridOption('serverSideDatasource', dataSource)
    }
  }

  const handlePageSizeChange = useCallback(
    (pageSize: string) => {
      if (gridRef.current)
        gridRef.current.api?.setGridOption(
          'paginationPageSize',
          parseInt(pageSize),
        )
      setSelectedPageSize(parseInt(pageSize))
      onPageSizeChange?.(parseInt(pageSize))
    },
    [setSelectedPageSize, onPageSizeChange],
  )

  const handleCellClick = useCallback(
    (e: CellClickedEvent) => {
      if (actions?.selectRow && e.column.getColId() !== 'actions') {
        actions.selectRow(e.data)
      }
    },
    [actions],
  )

  const defaultColDef: ColDef = useMemo(
    () => ({
      resizable: true,
      sortable: allowSorting,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      filter: 'agMultiColumnFilter',
      filterParams: getBaseFilterParams(),
      comparator: comparatorFunction,
    }),
    [allowSorting],
  )

  useEffect(() => {
    if (gridRef.current && shouldDeselect) {
      gridRef.current.api.deselectAll()
    }
  }, [gridRef, shouldDeselect])

  useEffect(() => {
    searchInputRef.current = searchInput
  }, [searchInput])

  useEffect(() => {
    if (
      currentPageSize &&
      gridRef.current?.api?.paginationGetPageSize() !== currentPageSize
    )
      handlePageSizeChange(currentPageSize?.toString())
  }, [currentPageSize, handlePageSizeChange])

  const decorated = useMemo(
    () =>
      data?.map(item => {
        return {
          ...item,
          isSelected: selectedRows.includes(item.id),
          selectedAxis: selectedScatter.find(
            scatter => scatter.id === item.id && scatter.axis,
          )?.axis,
          selectedX: !!selectedScatter.find(
            scatter => scatter.id === item.id && scatter.axis === ChartAxis.X,
          ),
          selectedY: !!selectedScatter.find(
            scatter => scatter.id === item.id && scatter.axis === ChartAxis.Y,
          ),
        }
      }),
    [data, selectedRows, selectedScatter],
  )

  const debouncedSearch = useMemo(
    () =>
      debounce((input: string) => {
        if (fuzzySearch) return
        gridRef.current?.api.setGridOption('quickFilterText', input)
      }, 500),
    [fuzzySearch, gridRef],
  )

  const debouncedFilterChange = useMemo(
    () =>
      debounce(() => {
        if (gridRef.current?.api) {
          gridRef.current.api.onFilterChanged()
        }
      }, 500),
    [gridRef],
  )

  useEffect(() => {
    if (!gridRef.current?.api) return
    debouncedSearch(searchInput)
  }, [searchInput, gridRef, debouncedSearch])

  useEffect(() => {
    debouncedFilterChange()
  }, [
    isExternalFilterPresent,
    doesExternalFilterPass,
    fuzzySearch,
    searchInput,
    debouncedFilterChange,
  ])

  // Save filter state to local storage
  const handleFilterChanged = useCallback((): void => {
    if (!gridRef.current?.api || !tablePersistence) return
    const filterState = gridRef.current.api.getFilterModel()
    localStorage.setItem(`${id}-filterState`, JSON.stringify(filterState))
  }, [id, tablePersistence])

  // Save sorting state to local storage
  const handleSortChanged = useCallback((): void => {
    if (!gridRef.current?.api || !tablePersistence) return
    const sortState = gridRef.current.api.getColumnState()
    localStorage.setItem(`${id}-columnState`, JSON.stringify(sortState))
  }, [id, tablePersistence])

  // Restore filter and sort state from local storage
  const onFirstDataRendered = useCallback((): void => {
    if (!tablePersistence) return
    const columnState = localStorage.getItem(`${id}-columnState`)
    const filterState = localStorage.getItem(`${id}-filterState`)
    if (columnState && gridRef.current?.api) {
      const savedColumns = JSON.parse(columnState)
      // Prevent reordering of columns if config is changed
      // Only apply saved state to columns that are still in the config
      const updatedColums = columns.map(col => {
        const savedCol = savedColumns.find(
          (savedCol: any) => savedCol.colId === col.field,
        )
        if (savedCol) {
          return savedCol
        }
        return {}
      })

      gridRef.current.api.applyColumnState({
        state: updatedColums,
        applyOrder: true,
      })
    }
    if (filterState && gridRef.current?.api) {
      // Set filter state after grid is rendered to prevent errors
      setTimeout(
        () => gridRef.current?.api.setFilterModel(JSON.parse(filterState)),
        0,
      )
    }
  }, [columns, id, tablePersistence])

  const doesFuzzyFilterPass = useCallback(
    (node: any): boolean => {
      if (!fuzzySearch || !fuzzySearchField) return true
      const pattern = searchInputRef.current.replaceAll(' ', '')
      // Check if any of the fuzzy search fields match the pattern
      return fuzzySearchField.some(field => {
        return hasMatch(pattern, getNestedValue(node.data, field) ?? '')
      })
    },
    [fuzzySearch, fuzzySearchField],
  )

  const isFuzzyFilterPresent = useCallback((): boolean => {
    return !!searchInputRef.current
  }, [])

  const handleFuzzySort = useCallback(
    ({ nodes }: { nodes: IRowNode[] }): void => {
      // If fuzzy search is not enabled or no field is provided, return
      if (!fuzzySearch || !fuzzySearchField || !searchInputRef.current) return

      // Sort by fuzzy search score
      nodes
        .sort((a, b) => {
          const pattern = searchInputRef.current.replaceAll(' ', '')
          // return the highest score of any fuzzy search fields
          const aScore = Math.max(
            ...fuzzySearchField.map(field => {
              if (hasMatch(pattern, a.data[field] ?? '')) {
                return score(pattern, a.data[field] ?? '')
              }
              return -Infinity
            }),
          )
          const bScore = Math.max(
            ...fuzzySearchField.map(field => {
              if (hasMatch(pattern, b.data[field] ?? '')) {
                return score(pattern, b.data[field] ?? '')
              }
              return -Infinity
            }),
          )
          return bScore - aScore
        })
        .map((node, index) => {
          // Update the row index to reflect the new order
          // This is needed for the rows to paginate correctly
          node.rowIndex = index
        })
    },
    [fuzzySearch, fuzzySearchField],
  )

  return (
    <div className="h-full">
      {allowSearch && (
        <div className="flex items-center justify-between gap-xs border border-b-0 border-solid border-[#e0e0e0] bg-[#fafafa] px-s py-2xs">
          <div>
            <Text variant="description" bold>
              {headerSummary}
            </Text>
          </div>
          <TextInput
            iconRight={light('search')}
            className="w-[200px]"
            placeholder="Search"
            variant="underlined"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            autofocus={shouldAutofocusSearch}
          />
        </div>
      )}
      <div
        id={id}
        ref={containerRef}
        className={classNames(
          'w-full flex-1',
          allowSearch ? 'h-[calc(100%-45px)]' : 'h-full',
        )}
      >
        <AgGridReact
          ref={gridRef}
          theme={tableTheme}
          getRowId={({ data: { id } }) => id}
          rowClass={actions?.selectRow ? 'cursor-pointer' : ''}
          rowModelType={dataSource ? 'serverSide' : undefined}
          columnMenu="legacy"
          className={className}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          columnDefs={columns}
          rowData={dataSource ? undefined : decorated}
          defaultColDef={defaultColDef}
          pagination={allowPaging}
          paginationPageSize={pageSize}
          paginationPageSizeSelector={false}
          rowHeight={rowHeight}
          headerHeight={42}
          onSortChanged={handleSortChanged}
          onFilterChanged={handleFilterChanged}
          onPaginationChanged={data => {
            if (data.newPage && onPageChange) {
              onPageChange(data.api.paginationGetCurrentPage())
            }
          }}
          enableCellTextSelection
          rowSelection={
            disallowSelection
              ? undefined
              : {
                  mode: selectMultipleRows ? 'multiRow' : 'singleRow',
                  checkboxes: false,
                  headerCheckbox: false,
                  enableClickSelection: true,
                }
          }
          onRowSelected={() =>
            actions?.setSelectedRows &&
            actions?.setSelectedRows(gridRef.current?.api.getSelectedRows())
          }
          suppressMenuHide={fixedColumnMenu}
          getContextMenuItems={getContextItems}
          onCellContextMenu={event => {
            // if none of the rows are selected, select the row that was right clicked
            if (getContextItems) event.node.setSelected(true)
          }}
          onCellClicked={handleCellClick}
          isExternalFilterPresent={
            fuzzySearch ? isFuzzyFilterPresent : isExternalFilterPresent
          }
          doesExternalFilterPass={
            fuzzySearch ? doesFuzzyFilterPass : doesExternalFilterPass
          }
          postSortRows={fuzzySearch ? handleFuzzySort : undefined}
          cacheBlockSize={cacheBlockSize}
        />
        {allowPaging && paginationPortal && (
          <PageSizeSelector
            target={paginationPortal}
            onChange={handlePageSizeChange}
            pageSizes={pageSizes}
            pageSize={{
              value: selectedPageSize?.toString() ?? '',
              label: selectedPageSize?.toString() ?? '',
            }}
          />
        )}
      </div>
    </div>
  )
}
