import Highlighter from 'react-highlight-words'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom'
import { Icon, Text, TableConfig, getBaseFilterParams } from 'src/components/ui'
import { ModelState } from 'pages/site/models'
import { ANOMALY_STATUS } from 'src/utility/constants/AnomalyConstFinal'
import { AnomalyDuration, AnomalyLiveTag, AnomalyTime } from './components'

export const getAnomalyTableConfig = (
  anomalyDashboard: boolean,
  filter = '',
): TableConfig<any> => {
  return {
    allowPaging: true,
    pageSize: 20,
    pageSizes: [20, 50, 100, 200],
    allowSorting: true,
    allowSearch: false,
    columns: [
      {
        field: 'inProgress',
        headerName: 'Live',
        minWidth: 100,
        initialFlex: 100,
        // For legacy column menu
        menuTabs: [],
        // For new column menu
        suppressHeaderFilterButton: true,
        suppressHeaderMenuButton: true,
        cellRenderer: ({ value }: any) => {
          return <AnomalyLiveTag inProgress={value} />
        },
      },
      {
        field: 'modelName',
        headerName: 'Model Name',
        minWidth: 400,
        filterParams: getBaseFilterParams(true),
        cellRenderer: ({ data: { modelName, tagName, tag, link } }: any) => (
          <Link
            to={link}
            className="flex size-full flex-col text-inherit no-underline"
          >
            <Text>
              <Highlighter
                searchWords={filter.split(' ')}
                textToHighlight={modelName ?? ''}
                autoEscape
              />
            </Text>
            <Text className="text-text-tertiary">
              Output tag:{' '}
              <Highlighter
                searchWords={filter.split(' ')}
                textToHighlight={tag?.displayName || tagName}
                autoEscape
              />
            </Text>
          </Link>
        ),
      },
      {
        field: 'parentTag',
        headerName: 'Parent Asset',
        minWidth: 150,
        cellRenderer: ({ data: { parentTag } }: any) => (
          <Text>
            <Highlighter
              searchWords={filter.split(' ')}
              textToHighlight={parentTag || ''}
              autoEscape
            />
          </Text>
        ),
      },
      {
        field: 'modelState',
        headerName: 'Model State',
        minWidth: 130,
        initialFlex: 150,
        cellRenderer: ({ data: { modelState } }: any) => (
          <ModelState hideBackground className="w-full" state={modelState}>
            <Highlighter
              searchWords={filter.split(' ')}
              textToHighlight={modelState || ''}
              autoEscape
            />
          </ModelState>
        ),
      },
      {
        field: 'from',
        headerName: 'Start Time',
        minWidth: 200,
        initialFlex: 200,
        filter: '',
        cellRenderer: ({ value }: any) => <AnomalyTime time={value} />,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        minWidth: 100,
        initialFlex: 150,
        filter: '',
        cellRenderer: ({ data }: any) => <AnomalyDuration anomaly={data} />,
      },
      {
        field: 'score',
        headerName: 'Peak Score',
        minWidth: 100,
        initialFlex: 120,
        cellRenderer: ({ value }: any) => (
          <Text>{typeof value === 'number' ? value.toFixed(4) : ''}</Text>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        initialFlex: 120,
        filterValueGetter: ({ data }: any) => {
          const value = ANOMALY_STATUS.find(
            option => option.value === data.state,
          )
          return !anomalyDashboard && value?.value === 'NEW'
            ? 'New'
            : value?.label
        },
        cellRenderer: ({ value }: any) => {
          const status = ANOMALY_STATUS.find(option => option.value === value)
          const label =
            status?.value === 'NEW' && !anomalyDashboard ? 'New' : status?.label

          return (
            <Text>
              <Highlighter
                searchWords={filter.split(' ')}
                textToHighlight={label ?? value ?? ''}
                autoEscape
              />
            </Text>
          )
        },
      },
      {
        field: 'commentsCount',
        headerName: 'Comments',
        cellRenderer: ({ value }: any) =>
          value > 0 ? (
            <div className="grid grid-flow-col items-center justify-end gap-[0.2em]">
              <Text>{value}</Text>
              <Icon icon={light('comment')} />
            </div>
          ) : null,
      },
    ],
  }
}
