import { useParams } from 'react-router-dom'
import { TimeRangeProvider } from 'src/contexts/timeRange'
import { FilterProvider } from 'src/contexts/filter'
import { Spinner } from 'src/components/ui'
import { ErrorDisplay } from 'pages/app'
import { TagData, TagOverview, ConnectedModels } from './components'
import { useTagById } from './api'

export function TagDetailsPage(): JSX.Element {
  const { tagId } = useParams()
  if (!tagId) throw new Error('`tagId` route param missing')
  const tagQuery = useTagById(tagId)

  if (tagQuery.isLoading) return <Spinner />

  if (tagQuery.isError || !tagQuery.data) {
    return (
      <ErrorDisplay
        error={tagQuery.error}
        message="Failed to fetch tag!"
        action={tagQuery.refetch}
      />
    )
  }

  return (
    <TimeRangeProvider>
      <FilterProvider>
        <div className="m-[1em] mt-0 flex flex-col gap-s">
          <TagOverview tagName={tagQuery.data.tagName} />
          <TagData tagName={tagQuery.data.tagName} />
          <ConnectedModels tagName={tagQuery.data.tagName} />
        </div>
      </FilterProvider>
    </TimeRangeProvider>
  )
}
