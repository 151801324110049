import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Text, TableConfig, Tooltip, Icon } from 'src/components/ui'
import { OrganizationStatus, SiteRole, SiteUser } from 'src/types'
import { HomeOrgField } from 'orgs-sites/org'
import { SiteRoleSelector } from './components'
import CancelInvitationIcon from './images/envelopeCrossed.svg'

interface SiteUsersTableConfigActions {
  openRemoveUserModal: (id: string) => void
  openCancelInvitationModal: (id: string) => void
  currentUser?: SiteUser
  onRoleChange: (role: SiteRole, userId: string) => Promise<void>
  orgId: string
}

export const siteUsersTableConfig = ({
  openRemoveUserModal,
  openCancelInvitationModal,
  currentUser,
  onRoleChange,
  orgId,
}: SiteUsersTableConfigActions): TableConfig<SiteUser> => {
  return {
    pageSize: 50,
    allowPaging: true,
    pageSizes: [10, 50, 100],
    allowSorting: true,
    columns: [
      {
        field: 'homeOrganization.name',
        headerName: 'Home Organization',
        initialFlex: 200,
        cellRenderer: ({ data: { homeOrganization } }: any) => {
          return (
            <HomeOrgField homeOrganization={homeOrganization} orgId={orgId} />
          )
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content" bold>
            {value || (
              <span className="text-text-tertiary">Not registered yet</span>
            )}
          </Text>
        ),
      },
      {
        field: 'email',
        headerName: 'Email Address',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content">{value}</Text>
        ),
      },
      {
        field: 'role',
        headerName: 'Site Role',
        initialFlex: 220,
        minWidth: 220,
        cellClass: 'selector-cell',
        cellRenderer: ({ data: { role, id } }: any) => {
          return (
            <SiteRoleSelector
              role={role}
              userId={id}
              handleSaveRole={onRoleChange}
              currentUser={currentUser}
            />
          )
        },
      },
      {
        field: 'addedBy',
        headerName: 'Added By',
        initialFlex: 200,
        cellRenderer: ({ value }: any) => (
          <Text variant="content" bold>
            {value}
          </Text>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        initialFlex: 200,
        cellRenderer: ({ data: { status, inviteDaysLeft } }: any) => (
          <Text
            variant="content"
            bold={
              status === OrganizationStatus.ACTIVE ||
              status === OrganizationStatus.PENDING
            }
            className={`${
              status === OrganizationStatus.PENDING && 'text-text-tertiary'
            }`}
          >
            {`${status}${
              inviteDaysLeft
                ? ` (${inviteDaysLeft} ${
                    inviteDaysLeft === 1 ? 'day' : 'days'
                  } left)`
                : ''
            }`}
          </Text>
        ),
      },
      {
        headerName: 'Actions',
        // For legacy column menu
        menuTabs: [],
        // For new column menu
        suppressHeaderFilterButton: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        cellRenderer: ({ data: { id, status } }: any) => (
          <>
            {currentUser?.id !== id && (
              <>
                {currentUser?.role === SiteRole.ADMIN && (
                  <>
                    {status === OrganizationStatus.PENDING ? (
                      <Tooltip
                        render={() => 'Cancel invitation'}
                        direction="bottom"
                      >
                        <div className="flex h-full items-center">
                          <img
                            onClick={() => openCancelInvitationModal(id)}
                            src={CancelInvitationIcon}
                            className="size-[21px]"
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip render={() => 'Remove user'} direction="bottom">
                        <div className="flex h-full items-center">
                          <div onClick={() => openRemoveUserModal(id)}>
                            <Icon
                              size="big"
                              icon={light('circle-xmark')}
                              className="text-text-danger"
                            />
                          </div>
                        </div>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ),
      },
    ],
  }
}
