import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
import { Icon, Text } from 'src/components/ui'

type SegmentedControlItem = {
  icon?: IconProp
  label: string
  value: string
  disabled?: boolean
}

type SegmentedControlProps = {
  value?: string
  items: SegmentedControlItem[]
  onChange: (value: string) => void
  disabled?: boolean
  className?: string
}

export function SegmentedControl({
  value,
  items,
  onChange,
  disabled,
  className,
}: SegmentedControlProps): JSX.Element {
  return (
    <div
      data-testid="segmented-control"
      className={classNames(
        'grid items-center gap-3xs max-w-max overflow-hidden rounded-[6px] border border-solid border-border-brand bg-background p-3xs',
        disabled && 'opacity-50',
        className,
      )}
      style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}
    >
      {items.map(item => (
        <div
          key={item.value}
          onClick={() => {
            if (disabled || item.disabled || value === item.value) return
            onChange(item.value)
          }}
          className={classNames(
            'flex whitespace-nowrap overflow-hidden min-w-[120px] flex-1 items-center justify-center gap-xs px-xs py-2xs transition duration-300 rounded-2xs',
            value === item.value && 'bg-background-brand',
            disabled || item.disabled || value === item.value
              ? 'cursor-default'
              : 'cursor-pointer hover:bg-background-brand-hover',
            item.disabled && 'opacity-50',
          )}
        >
          {item.icon && (
            <Icon data-testid={`${item.value}-icon`} icon={item.icon} />
          )}
          <Text bold className="truncate">
            {item.label}
          </Text>
        </div>
      ))}
    </div>
  )
}
