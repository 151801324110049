import { batch } from '@preact/signals-react'
import classNames from 'classnames'
import { minutesToSeconds } from 'date-fns'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useModelTypes } from 'models/api'
import {
  ForecastDefaultMethod,
  forecastHorizonOptions,
  getModelSubType,
  NEW,
} from 'models/model/model.utils'
import { useSite } from 'src/contexts/site'
import { SelectInput, Text, TimePickerInput } from 'src/components/ui'
import { OnlyModelProps, SiteRole, TimeRangeOrPeriod } from 'src/types'
import { prettyDateRange } from 'src/utility/time'
import { isModelEditing, modelValues } from '../modelOverview.state'
import { PropertyContainer } from './PropertyContainer'

function ModelTypeInput(): JSX.Element {
  const typeId = modelValues.typeId.value
  const methodId = modelValues.methodId.value
  const forecastHorizon = modelValues.forecastHorizon.value
  const modelTypesQuery = useModelTypes()
  const modelTypes = modelTypesQuery.data ?? []
  const modelType = modelTypes.find(({ id }) => id === typeId)
  const methods = modelType?.methods

  return (
    <>
      <div className="flex items-center gap-2xs">
        <Text bold>Type</Text>
        <div className="w-[170px]">
          <SelectInput
            value={
              typeId
                ? {
                    label:
                      modelTypes.find(({ id }) => id === typeId)?.name || '',
                    value: typeId,
                  }
                : undefined
            }
            options={modelTypes.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            onChange={value => {
              if (value !== typeId) {
                const type = modelTypes.find(({ id }) => id === value)
                const methodId =
                  type?.methods.find(({ name }) =>
                    type.name === 'Forecast'
                      ? name === ForecastDefaultMethod.FORECAST_WITH_HORIZON
                      : name === 'Nowcast',
                  )?.id ?? 'unknown'
                batch(() => {
                  modelValues.typeId.value = value
                  modelValues.methodId.value = methodId
                  modelValues.forecastHorizon.value =
                    type?.name === 'Forecast' ? minutesToSeconds(60) : null
                })
              }
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <Text bold>
          {modelType?.name !== 'Forecast' ? 'Subtype' : 'Forecast Horizon'}
        </Text>
        <div className="w-[100px]">
          <SelectInput
            dropdownClassName="max-h-[210px] overflow-y-auto"
            options={
              modelType?.name !== 'Forecast'
                ? methods
                    ?.filter(m => m.name !== 'Forecast with horizon')
                    .map(({ id, name }) => ({
                      label: getModelSubType(name),
                      value: id,
                    })) ?? []
                : forecastHorizonOptions.map(({ label, value }) => ({
                    label,
                    value: value.toString(),
                  }))
            }
            value={
              typeId
                ? {
                    label: getModelSubType(
                      methods?.find(m => m.id === methodId)?.name ?? 'unknown',
                      forecastHorizon,
                    ),
                    value: methodId,
                  }
                : undefined
            }
            onChange={value => {
              if (modelType?.name !== 'Forecast')
                modelValues.methodId.value = value
              else modelValues.forecastHorizon.value = parseInt(value)
            }}
          />
        </div>
      </div>
    </>
  )
}

export function ModelTypeContainer({ model }: OnlyModelProps): JSX.Element {
  const { viewerRole: role } = useSite()

  const fromDate = model.activeTrainedModel?.dataStart || model.trainingStart
  const toDate = model.activeTrainedModel?.dataEnd || model.trainingEnd

  return (
    <div
      className={classNames(
        'flex whitespace-nowrap',
        isModelEditing.value
          ? 'flex-col gap-s'
          : 'flex-col gap-s small:gap-l small:flex-row small:items-center',
      )}
    >
      {isModelEditing.value &&
      model.state === NEW &&
      role !== SiteRole.READER ? (
        <>
          <div className="flex flex-col gap-s small:flex-row small:items-center">
            <ModelTypeInput />
          </div>
          <div className="flex items-center gap-2xs whitespace-nowrap">
            <Text bold>Training Period</Text>
            <TimePickerInput
              value={modelValues.trainingPeriod.value as TimeRangeOrPeriod}
              isLive={false}
              onChange={period => (modelValues.trainingPeriod.value = period)}
              maxDate={new Date()}
              position="left"
              showTimeOption={false}
            />
          </div>
        </>
      ) : (
        <>
          <PropertyContainer modelType={model.type.name}>
            <Text bold>Type</Text>
            <Text>
              {model.type.name},{' '}
              {getModelSubType(
                model.method.name,
                model.__typename === 'ForecastModel'
                  ? model.forecastHorizon
                  : null,
              )}
            </Text>
          </PropertyContainer>

          <PropertyContainer modelType={model.type.name}>
            <Text bold>Training Period</Text>
            <Text>
              {prettyDateRange({
                fromDate,
                toDate,
                timeInterval:
                  fromDate && toDate ? 'range' : model.trainingPeriod || 'P1Y',
              })}
            </Text>
          </PropertyContainer>
        </>
      )}
    </div>
  )
}
