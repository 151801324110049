import { ColDef, IRowNode } from 'ag-grid-community'
import { Text } from 'src/components/ui'
import { GqlOpcUaNodeClass } from 'src/services'
import { TagMenu } from './components'

export function getHierarchyTreeConfig(): ColDef[] {
  return [
    {
      field: 'dataType',
      initialFlex: 200,
      resizable: true,
      minWidth: 150,
      cellRenderer: ({ data, value }: any) => {
        if (value) return <Text>{value}</Text>
        if (data.class === GqlOpcUaNodeClass.Variable)
          return <Text>unknown</Text>
        return ''
      },
    },
    {
      field: 'actions',
      resizable: false,
      minWidth: 60,
      maxWidth: 60,
      cellClass: 'selector-cell',
      headerComponent: () => null,
      cellRenderer: ({ data }: { data: any; node: IRowNode }) => {
        return <TagMenu tagName={data.nodeId} />
      },
    },
  ]
}
