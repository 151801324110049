import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom'
import { Icon, Text } from 'src/components/ui'
import { GqlTagClassification, ModelTagBase, TagDto } from 'src/services'
import { ModelTypes, SiteRole } from 'src/types'

export const getInputTagWarnings = (
  role: SiteRole,
  rootLink: string,
  tag: TagDto,
  modelType?: ModelTypes | null,
  outputTagName?: string | null,
): React.ReactNode[] => {
  const warnings = []
  if (!tag.overriddenLabel && !tag.automaticLabel) {
    warnings.push(
      <span>
        This tag needs to be analyzed and labeled before use.{' '}
        {role === SiteRole.ADMIN ? (
          <Link
            to={`${rootLink}/tags/${tag.tagNodeId}`}
            className="text-text-brand"
          >
            <Text className="inline">
              Click here to label <Icon icon={light('external-link-alt')} />
            </Text>
          </Link>
        ) : (
          <span>
            You need to contact your admin and ask them to update the label.
          </span>
        )}
      </span>,
    )
  }

  if (modelType === 'AnomalyModel' && outputTagName === tag.tagName) {
    warnings.push(
      'Anomaly models of type nowcast do not allow using the output as input. To fix this issue, either remove the output from the list of inputs or change the model type once the model has been created.',
    )
  }

  return warnings
}

export function getOutputTagWarnings(
  role: SiteRole,
  rootLink: string,
  tag?: TagDto,
): Array<string | React.ReactNode> {
  if (!tag) return []
  return getOutputWarnings(
    role,
    tag.tagNodeId,
    rootLink,
    tag.overriddenLabel ?? tag.automaticLabel,
  )
}

export function getOutputTagWarningsFromModelTag(
  role: SiteRole,
  rootLink: string,
  tag?: ModelTagBase,
): React.ReactNode[] {
  if (!tag) return []
  return getOutputWarnings(
    role,
    tag.id,
    rootLink,
    tag.overriddenLabel ?? tag.automaticLabel,
  )
}

function getOutputWarnings(
  role: SiteRole,
  tagNodeId: string,
  rootLink: string,
  label?: GqlTagClassification | null,
): React.ReactNode[] {
  const warnings = []
  if (!label) {
    warnings.push(
      <span>
        This tag needs to be analyzed and labeled before use.{' '}
        {role === SiteRole.ADMIN ? (
          <Link
            to={`${rootLink}/tags/${tagNodeId}`}
            className="text-text-brand"
          >
            <Text className="inline">
              Click here to label <Icon icon={light('external-link-alt')} />
            </Text>
          </Link>
        ) : (
          <span>
            You need to contact your admin and ask them to update the label.
          </span>
        )}
      </span>,
    )
  }
  if (label === GqlTagClassification.Categorical) {
    warnings.push(
      'Selecting a categorical value like Step as output tag is not supported.',
    )
  }
  return warnings
}
