import { GqlAnomalyState } from 'src/services'
import { AnomalyColor } from 'src/types'

export const ANOMALY_STATUS: Array<{
  value: GqlAnomalyState
  label: string
  color: AnomalyColor
}> = [
  { value: 'NEW', label: 'Awaiting Review', color: 'red' },
  { value: 'ANOMALY', label: 'Anomaly', color: 'red' },
  { value: 'NOT_ANOMALY', label: 'Not Anomaly', color: 'green' },
  { value: 'UNCERTAIN', label: 'Uncertain', color: 'green' },
]
