import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import MenuWrapper from 'react-popper-tooltip'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { Checkbox, Icon, NumberInput, Text } from 'src/components/ui'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { ChartOptions, SeriesOptions } from 'src/types/chartTypes'
import { seriesIdToString } from 'src/contexts/charts'
import { ChartData } from '../../useChartData'
import { Markers, SeriesConfig, SettingsAccordion } from './chart-settings'

interface MenuProps {
  chart: ChartOptions
  data: ChartData[]
  setOptions: (index: number, options: Partial<SeriesOptions>) => void
  setChart: (options: ChartOptions) => void
  isModal?: boolean
}

export default function GraphSettings({
  chart,
  data,
  setOptions,
  setChart,
  isModal,
}: MenuProps): JSX.Element {
  const [isAxisOpen, setIsAxisOpen] = useState(true)
  const [isMarkersOpen, setIsMarkersOpen] = useState(false)
  const [isSeriesOpen, setIsSeriesOpen] = useState(true)
  const [selected, setSelected] = useState(chart.commonY ?? false)
  const [localChart, setLocalChart] = useState(chart)

  const debouncedSetChart = useMemo(
    () => debounce(options => setChart(options), 500),
    [setChart],
  )

  useEffect(() => {
    setLocalChart(chart)
  }, [chart])

  useEffect(() => {
    setIsMarkersOpen(!!chart.commonY)
  }, [chart.commonY])

  return (
    <MenuWrapper
      placement="left"
      trigger="click"
      tooltip={({ tooltipRef: ref, getTooltipProps }) => (
        <div
          className="flex max-h-[350px] min-w-[281px] flex-col gap-xs overflow-y-auto rounded-2xs border border-solid border-border bg-background px-xs py-s shadow-lg"
          {...getTooltipProps({
            ref,
            style: {
              zIndex: isModal ? zIndex.modalLegendMenu : zIndex.trendLegendMenu,
            },
          })}
        >
          <Text variant="content" bold>
            Chart Settings
          </Text>

          <SettingsAccordion
            label="Axis Settings"
            isOpen={isAxisOpen}
            setIsOpen={setIsAxisOpen}
          >
            <div className="flex flex-col gap-xs">
              <div className="flex items-center gap-2xs">
                <Checkbox
                  value={selected}
                  onChange={val => {
                    setSelected(val)
                    setChart({ ...chart, commonY: val })
                  }}
                />
                <Text variant="small" bold>
                  Common Y Axis
                </Text>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex w-[110px] items-center gap-xs">
                  <Text variant="small" bold>
                    Min:
                  </Text>
                  <NumberInput
                    disabled={!selected}
                    value={localChart.min}
                    allowUndefined
                    onChange={val => {
                      setLocalChart({ ...localChart, min: val })
                      debouncedSetChart({ ...chart, min: val })
                    }}
                    placeholder="Auto"
                  />
                </div>

                <div className="flex w-[110px] items-center gap-xs">
                  <Text variant="small" bold>
                    Max:
                  </Text>
                  <NumberInput
                    disabled={!selected}
                    value={chart.max}
                    allowUndefined
                    onChange={val => {
                      debouncedSetChart({ ...chart, max: val })
                    }}
                    placeholder="Auto"
                  />
                </div>
              </div>
            </div>
          </SettingsAccordion>

          <SettingsAccordion
            disabled={!selected}
            label="Markers"
            isOpen={isMarkersOpen}
            tooltip={!selected ? 'Enable common Y-axis' : undefined}
            setIsOpen={setIsMarkersOpen}
            isModal={isModal}
          >
            <Markers chart={chart} setChart={setChart} />
          </SettingsAccordion>

          <SettingsAccordion
            label="Tag Details"
            isOpen={isSeriesOpen}
            setIsOpen={setIsSeriesOpen}
          >
            <>
              {data.map((data, index) => {
                const seriesConfig = chart.data[index]
                return (
                  <SeriesConfig
                    key={seriesIdToString(seriesConfig)}
                    data={data}
                    series={seriesConfig}
                    minDisabled={!!chart.min || chart.commonY}
                    maxDisabled={!!chart.max || chart.commonY}
                    colorIndex={chart.colorIndex ?? chart.id + index}
                    setOptions={options => setOptions(index, options)}
                  />
                )
              })}
            </>
          </SettingsAccordion>
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef: ref }) => (
        <div
          className="mx-xs flex w-[100px] cursor-pointer items-center gap-2xs leading-[0]"
          {...getTriggerProps({ ref })}
        >
          <Icon icon={light('gear')} />
          <Text variant="description" bold>
            Chart Settings
          </Text>
        </div>
      )}
    </MenuWrapper>
  )
}
