import { memo, useMemo } from 'react'
import { subDays } from 'date-fns'
import { Banner, Button, Spinner } from 'src/components/ui'
import { useFavouriteModelsIds, useModels } from 'models/api'
import { useAnomalies } from 'anomalies/api'
import { useSite } from 'src/contexts/site'
import { RecentAnomalies } from './components'

export const RecentAnomaliesCard = memo(
  function AnomalyDistributionCard(): JSX.Element {
    const { id } = useSite()
    const favoriteModelsQuery = useFavouriteModelsIds()
    const modelsQuery = useModels()
    const now = useMemo(() => new Date(), [])
    const from = subDays(now, 7)
    const anomaliesQuery = useAnomalies(
      id,
      from.valueOf(),
      now.valueOf(),
      data => data.filter(a => favoriteModelsQuery.data?.has(a.model.id)),
    )

    if (
      modelsQuery.isLoading ||
      favoriteModelsQuery.isLoading ||
      anomaliesQuery.isLoading
    ) {
      return <Spinner />
    }

    if (
      modelsQuery.isError ||
      favoriteModelsQuery.isError ||
      anomaliesQuery.isLoading
    ) {
      const errorQuery = modelsQuery.isError
        ? modelsQuery
        : favoriteModelsQuery.isError
        ? favoriteModelsQuery
        : anomaliesQuery
      return (
        <Banner
          variant="error"
          rightComponent={
            <Button
              variant="primary"
              title="Try again"
              onClick={() => errorQuery.refetch()}
            />
          }
        >
          Failed to fetch data
        </Banner>
      )
    }

    const hasFavoriteAnomalyModels = modelsQuery.data.some(
      m =>
        m.__typename === 'AnomalyModel' && favoriteModelsQuery.data?.has(m.id),
    )

    return (
      <RecentAnomalies
        anomalies={anomaliesQuery.data ?? []}
        hasFavoriteModels={hasFavoriteAnomalyModels}
      />
    )
  },
)
