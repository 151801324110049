import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTitle } from 'src/utility'
import { Text, Spinner, Card, Icon } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { ErrorDisplay } from 'pages/app'
import { useAnomaly } from 'anomalies/api'
import { useNavigationContext } from 'src/contexts/navigation'
import { Comments, Details, Overview } from './sections'

export function AnomalyPage(): JSX.Element {
  const { anomalyId } = useParams()
  if (!anomalyId) throw new Error('`anomalyId` route param missing')
  useTitle(`Anomaly ${anomalyId}`)
  const { id: factory } = useSite()
  const { setTitleComponent } = useNavigationContext()
  const navigate = useNavigate()
  const location = useLocation()

  const anomalyQuery = useAnomaly(factory, anomalyId)

  useEffect(() => {
    setTitleComponent(
      <div className="flex items-center gap-xs">
        {/** If it's possible to navigate back and stay in the app */}
        {location.key !== 'default' && (
          <Icon
            icon={regular('chevron-left')}
            className="aspect-square cursor-pointer rounded-full p-3xs transition duration-300 hover:bg-background-hover"
            onClick={() => navigate(-1)}
          />
        )}

        <Text variant="title" bold>
          Anomalies
        </Text>
      </div>,
    )
    return () => setTitleComponent(null)
  }, [location.key, navigate, setTitleComponent])

  if (anomalyQuery.isError) {
    return (
      <ErrorDisplay error={anomalyQuery.error} message="Anomaly not found" />
    )
  }

  if (anomalyQuery.isLoading) {
    return (
      <Card>
        <Text variant="title" bold className="!text-2xl">
          Anomaly
        </Text>
        <Spinner />
      </Card>
    )
  }

  return (
    <div className="m-[0.5em] mt-0 grid grid-cols-[minmax(0,1fr)_26em] gap-[0.5em]">
      <>
        <Overview anomaly={anomalyQuery.data} />
        <Comments anomaly={anomalyQuery.data} />
        <Details anomaly={anomalyQuery.data} />
      </>
    </div>
  )
}
