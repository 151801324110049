import classNames from 'classnames'

const variants = {
  title: 'text-title font-rubik leading-title my-0',
  content: 'text-content font-roboto leading-content my-0',
  'content-rubik': 'text-content font-rubik leading-content my-0',
  description: 'text-description font-roboto leading-description my-0',
  small: 'text-small font-roboto leading-small my-0',
  code: 'text-code font-roboto-mono leading-code my-0',
}

type Props = {
  children: React.ReactNode
  className?: string
  variant?: keyof typeof variants
  bold?: boolean
  testId?: string
}

export function Text({
  children,
  className,
  variant = 'content',
  bold = false,
  testId,
}: Props): JSX.Element {
  return (
    <p
      data-testid={testId || 'text'}
      className={classNames(
        variants[variant],
        bold ? 'font-500' : 'font-300',
        className,
      )}
    >
      {children}
    </p>
  )
}
