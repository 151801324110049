import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Card } from 'src/components/ui'
import { useTitle } from 'src/utility'
import {
  AnomalyCommentsCard,
  RecentAnomaliesCard,
  AssetStatusCard,
  MetropolisDashboardCard,
  AnomalyStatusCard,
} from './cards'

type DashboardProps = {
  kind: 'default' | 'metropolis'
  rootLink?: string
}

export function DashboardPage({
  kind,
  rootLink = '',
}: DashboardProps): JSX.Element {
  useTitle('Dashboard')

  const { anomalyDashboard } = useFlags()

  if (kind === 'metropolis') {
    return (
      <div className="mx-4 mb-4">
        <Card>
          <MetropolisDashboardCard />
        </Card>
      </div>
    )
  }

  return (
    <div
      className={classNames(
        'mx-4 mb-4 grid gap-4',
        anomalyDashboard ? 'grid-cols-6' : 'grid-cols-2',
      )}
    >
      {anomalyDashboard ? (
        <Card padding="24px" className="col-span-6 @container">
          <RecentAnomaliesCard />
        </Card>
      ) : (
        <Card as={Link} to={`${rootLink}/assets`} $hovercolor>
          <AssetStatusCard />
        </Card>
      )}
      <Card
        padding="24px"
        className={classNames(
          anomalyDashboard ? 'col-span-6 md:col-span-4' : 'row-span-3',
        )}
      >
        <AnomalyCommentsCard />
      </Card>

      {anomalyDashboard ? (
        <Card
          as={Link}
          to={`${rootLink}/assets`}
          className="col-span-6 self-start md:col-span-2"
          $hovercolor
        >
          <AssetStatusCard />
        </Card>
      ) : (
        <Card as={Link} to={`${rootLink}/anomalies`} $hovercolor>
          <AnomalyStatusCard />
        </Card>
      )}
    </div>
  )
}
