import { parseISO } from 'date-fns'
import {
  GqlActiveTrainedModelFragment,
  GqlFetchModel_AnomalyModel_Fragment,
  GqlFetchModel_ForecastModel_Fragment,
  GqlFetchModel_PrescriptiveModel_Fragment,
  GqlFetchModelFragment,
  GqlModelAnomalyFragment,
  GqlModelTagBaseFragment,
} from '../graphqlTypes'
import {
  ActiveTrainedModel,
  FetchAnomalyModel,
  FetchForecastModel,
  FetchModel,
  FetchPrescriptiveModel,
  ModelAnomaly,
  ModelBase,
  ModelTagBase,
} from './model.types'

export function mapApiToFetchModel(model: GqlFetchModelFragment): FetchModel {
  if (model.__typename === 'AnomalyModel') {
    return {
      ...mapApiToFetchAnomalyModel(model),
    }
  }
  if (model.__typename === 'PrescriptiveModel') {
    return {
      ...mapApiToFetchPrescriptiveModel(model),
    }
  }
  return {
    ...mapApiToFetchForecastModel(model),
  }
}

function mapApiToModelBase(model: GqlFetchModelFragment): ModelBase {
  return {
    ...model,
    created: parseISO(model.created),
    trainingStart: model.trainingStart
      ? parseISO(model.trainingStart)
      : undefined,
    trainingEnd: model.trainingEnd ? parseISO(model.trainingEnd) : undefined,
    activeTrainedModel: model.activeTrainedModel
      ? mapApiToActiveTrainedModel(model.activeTrainedModel)
      : undefined,
    tag: mapApiToModelTagBase(model.tag),
    inputTags: model.inputTags.map(mapApiToModelTagBase),
  }
}

function mapApiToFetchAnomalyModel(
  model: GqlFetchModel_AnomalyModel_Fragment,
): FetchAnomalyModel {
  return {
    ...mapApiToModelBase(model),
    __typename: 'AnomalyModel',
    availableErrorScores: model.availableErrorScores,
    anomalyConditions: model.anomalyConditions,
    anomalies: model.anomalies.map(mapApiToModelAnomaly),
    initialInferenceStart: model.initialInferenceStart
      ? parseISO(model.initialInferenceStart)
      : undefined,
    initialInferenceEnd: model.initialInferenceEnd
      ? parseISO(model.initialInferenceEnd)
      : undefined,
  }
}

function mapApiToModelAnomaly(
  modelAnomaly: GqlModelAnomalyFragment,
): ModelAnomaly {
  return {
    ...modelAnomaly,
    start: parseISO(modelAnomaly.start),
    end: modelAnomaly.end ? parseISO(modelAnomaly.end) : undefined,
  }
}

function mapApiToModelTagBase(modelTag: GqlModelTagBaseFragment): ModelTagBase {
  return {
    ...modelTag,
    availableDataTimeRange: modelTag.availableDataTimeRange
      ? {
          ...modelTag.availableDataTimeRange,
          min: parseISO(modelTag.availableDataTimeRange.min),
          max: parseISO(modelTag.availableDataTimeRange.max),
        }
      : undefined,
  }
}

function mapApiToFetchForecastModel(
  model: GqlFetchModel_ForecastModel_Fragment,
): FetchForecastModel {
  return {
    ...mapApiToModelBase(model),
    __typename: 'ForecastModel',
    initialInferenceStart: model.initialInferenceStart
      ? parseISO(model.initialInferenceStart)
      : undefined,
    initialInferenceEnd: model.initialInferenceEnd
      ? parseISO(model.initialInferenceEnd)
      : undefined,
  }
}

function mapApiToFetchPrescriptiveModel(
  model: GqlFetchModel_PrescriptiveModel_Fragment,
): FetchPrescriptiveModel {
  return {
    ...mapApiToModelBase(model),
    __typename: 'PrescriptiveModel',
    initialInferenceStart: model.initialInferenceStart
      ? parseISO(model.initialInferenceStart)
      : undefined,
    initialInferenceEnd: model.initialInferenceEnd
      ? parseISO(model.initialInferenceEnd)
      : undefined,
  }
}

function mapApiToActiveTrainedModel(
  activeTrainedModel: GqlActiveTrainedModelFragment,
): ActiveTrainedModel {
  return {
    ...activeTrainedModel,
    dataStart: parseISO(activeTrainedModel.dataStart),
    dataEnd: parseISO(activeTrainedModel.dataEnd),
  }
}
