import { IRowNode } from 'ag-grid-community'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon } from 'src/components/ui'
import { ErrorNode, ROOT_NODE_ID } from '../../opc-ua-connection.types'
import { constructRoute } from '../opcUaBrowse.utils'

export function LoadingErrorRenderer({
  node,
  failedNodes,
  refreshCache,
  isRoot,
}: {
  node: IRowNode
  failedNodes: ErrorNode[]
  refreshCache: (node: IRowNode, isRoot?: boolean) => void
  isRoot?: boolean
}): JSX.Element {
  const route = constructRoute(node)
  const failedNode = isRoot
    ? failedNodes.find(n => n.id === ROOT_NODE_ID)
    : failedNodes.find(errNode => errNode.id === node.parent?.key)

  return (
    <div
      className="mx-[26px] flex w-full items-center justify-between"
      style={{ marginLeft: (route.length - 1) * 28 + 17 }}
    >
      <div className="flex items-center gap-xs">
        <Icon
          size="regular"
          icon={light('circle-exclamation')}
          className="text-text-danger"
        />
        Failed to load (<span>{failedNode?.reason || 'Unknown error'}</span>)
      </div>
      <Icon
        icon={light('rotate-right')}
        className="cursor-pointer"
        size="regular"
        onClick={() => refreshCache(node.parent!, isRoot)}
      />
    </div>
  )
}
