import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  exactDuration,
  humanDateTime,
  timeAgo,
  humanDuration,
} from 'src/utility/time'
import { useSite } from 'src/contexts/site'
import {
  Text,
  Card,
  Tooltip,
  Icon,
  SegmentedControl,
  EditableField,
  SelectInput,
} from 'src/components/ui'
import { useSetAnomalyStateMutation } from 'anomalies/api'
import { Anomaly, GqlAnomalyState } from 'src/services'
import { AnomalyLiveTag } from 'anomalies/anomalies'
import { mapReadableAnomalyState } from 'anomalies/anomaly.utils'
import { ANOMALY_STATUS } from 'src/utility/constants/AnomalyConstFinal'

const allowedStatuses: GqlAnomalyState[] = [
  GqlAnomalyState.New,
  GqlAnomalyState.Anomaly,
  GqlAnomalyState.NotAnomaly,
  GqlAnomalyState.Uncertain,
]

interface AnomalyOverviewProps {
  anomaly: Anomaly
  className?: string
}

export function Overview({
  anomaly,
  className,
}: AnomalyOverviewProps): JSX.Element {
  const { rootLink } = useSite()
  const mutation = useSetAnomalyStateMutation()
  const [anomalyState, setAnomalyState] = useState<GqlAnomalyState>(
    anomaly.state,
  )
  const { anomalyDashboard } = useFlags()

  function handleAnomalyStateChange(state: GqlAnomalyState): void {
    // Optimistically update the state
    setAnomalyState(state)

    mutation.mutateAsync(
      {
        anomalyId: anomaly.id,
        state,
      },
      {
        onError: () => {
          // Revert to previous state
          setAnomalyState(anomaly.state)
          toast.error('Failed to update anomaly status.', {
            position: 'top-right',
          })
        },
        onSuccess: () => {
          toast.success('Anomaly status updated.', {
            position: 'top-right',
          })
        },
      },
    )
  }

  const options = ANOMALY_STATUS.filter(s =>
    allowedStatuses.includes(s.value),
  ).map(o => {
    if (!anomalyDashboard && o.value === 'NEW')
      return {
        ...o,
        label: 'New',
      }

    return o
  })

  const currentState =
    options.find(o => o.value === anomaly.state) ?? options[0]
  const initialState =
    !anomalyDashboard && currentState.value === 'NEW'
      ? { ...currentState, label: 'New' }
      : currentState
  return (
    <Card className={classNames('@container col-span-2', className)}>
      <div className="flex w-full flex-wrap items-center gap-s">
        <Text variant="title" bold className="!text-2xl">
          Anomaly {anomaly.id}
        </Text>
        <AnomalyLiveTag
          inProgress={anomaly.inProgress}
          className="w-full max-w-[100px]"
        />
      </div>
      <div>
        <Text className="my-xs">
          Model:{' '}
          <Link to={`${rootLink}/models/${anomaly.model.id}`}>
            {anomaly.model.name}
          </Link>
        </Text>
      </div>
      <Text className="my-xs">
        Start time: {humanDateTime(anomaly.start)} ({timeAgo(anomaly.start)})
      </Text>
      {anomaly.end && (
        <Text className="my-xs">
          Duration:{' '}
          <span title={exactDuration(anomaly.start, anomaly.end)}>
            {humanDuration(anomaly.start, anomaly.end)}
          </span>
        </Text>
      )}
      <Text className="my-xs">
        Peak score:{' '}
        <span>{anomaly.score ? anomaly.score.toFixed(4) : '-'}</span>
      </Text>
      {anomalyDashboard ? (
        <>
          <hr className="my-s bg-border" />
          <div className="flex flex-col gap-2xs">
            <div className="flex items-center gap-2xs">
              <Text bold>Anomaly Status</Text>
              <Text>
                {(
                  [
                    GqlAnomalyState.InProgress,
                    GqlAnomalyState.New,
                  ] as GqlAnomalyState[]
                ).includes(anomaly.state)
                  ? 'Awaiting Review'
                  : mapReadableAnomalyState(anomaly.state)}
              </Text>
            </div>
            <div className="flex items-center gap-xs">
              <Text>Is this a correctly identified anomaly?</Text>
              <Tooltip
                direction="right"
                render={() => (
                  <div className="flex flex-col gap-xs">
                    <Text>
                      Categorizing anomalies helps track the performance of the
                      model. It can help identify real issues to be addressed in
                      your process and find areas for model improvement.
                    </Text>
                    <Text>
                      Is Anomaly: This anomaly detected a real deviation from
                      the normal behavior of your process.
                    </Text>
                    <Text>
                      Is not Anomaly: This anomaly reflects normal behavior and
                      did not require attention.
                    </Text>
                    <Text>
                      Uncertain: For anomalies that have been reviewed but
                      remain inconclusive.
                    </Text>
                  </div>
                )}
              >
                <Icon icon={light('question-circle')} />
              </Tooltip>
            </div>
            <SegmentedControl
              className="mt-xs"
              value={anomalyState}
              onChange={value =>
                handleAnomalyStateChange(value as GqlAnomalyState)
              }
              items={[
                {
                  label: 'Is Anomaly',
                  value: GqlAnomalyState.Anomaly,
                  icon: light('bug'),
                },
                {
                  label: 'Is not Anomaly',
                  value: GqlAnomalyState.NotAnomaly,
                  icon: light('bug-slash'),
                },
                {
                  label: 'Uncertain',
                  value: GqlAnomalyState.Uncertain,
                  icon: light('scale-unbalanced-flip'),
                },
              ]}
            />
          </div>
        </>
      ) : (
        <EditableField
          initialState={initialState}
          renderDisplay={() => <Text>Status: {initialState.label}</Text>}
          renderInput={({ state, setState }) => (
            <>
              <Text>Status:</Text>
              <div className="w-[190px]">
                <SelectInput
                  value={state}
                  options={options}
                  onChange={value => {
                    const newValue = options.find(el => el.value === value)
                    if (newValue) setState(newValue)
                  }}
                />
              </div>
            </>
          )}
          updateStatus={mutation.status}
          onSave={async state => {
            handleAnomalyStateChange(state.value)
          }}
          saveDisabled={state =>
            state?.value === anomaly.state || mutation.isLoading
          }
          isEditable
        />
      )}
    </Card>
  )
}
