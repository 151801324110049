import Highlighter from 'react-highlight-words'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { TableAnomaly } from 'anomalies/anomaly.types'
import { AnomalyDuration, AnomalyTime } from 'anomalies/anomalies'
import { TableConfig, Text, Icon } from 'src/components/ui'

interface Actions {
  renderAction: any
}

export const getAnomaliesModelConfig = ({
  renderAction,
}: Actions): TableConfig<TableAnomaly> => {
  const filter = ''

  return {
    allowPaging: true,
    pageSize: 5,
    pageSizes: [5, 10, 50, 100],
    allowSorting: true,
    columns: [
      {
        field: 'from',
        headerName: 'Time',
        minWidth: 200,
        initialFlex: 200,
        // For legacy column menu
        menuTabs: [],
        // For new column menu
        suppressHeaderFilterButton: true,
        suppressHeaderMenuButton: true,
        cellRenderer: ({ value }: any) => <AnomalyTime time={value} />,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        minWidth: 100,
        initialFlex: 100,
        filter: '',
        // For legacy column menu
        menuTabs: [],
        // For new column menu
        suppressHeaderFilterButton: true,
        suppressHeaderMenuButton: true,
        cellRenderer: ({ data }: any) => <AnomalyDuration anomaly={data} />,
      },
      {
        field: 'score',
        headerName: 'Peak Score',
        minWidth: 100,
        initialFlex: 120,
        filter: '',
        cellRenderer: ({ value }: any) => (
          <Text>{typeof value === 'number' ? value.toFixed(4) : ''}</Text>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        initialFlex: 120,
        filter: '',
        cellRenderer: ({ value }: any) => (
          <Text>
            <Highlighter
              searchWords={filter.split(' ')}
              textToHighlight={value ?? ''}
              autoEscape
            />
          </Text>
        ),
      },
      {
        field: 'commentsCount',
        headerName: 'Comments',
        initialFlex: 100,
        filter: '',
        cellRenderer: ({ value }: any) =>
          value > 0 ? (
            <div className="grid grid-flow-col items-center justify-end gap-1">
              {value}
              <Icon icon={light('comment')} />
            </div>
          ) : null,
        width: 100,
      },
      {
        headerName: '',
        cellRenderer: renderAction,
        // For legacy column menu
        menuTabs: [],
        // For new column menu
        suppressHeaderFilterButton: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        minWidth: 350,
      },
    ],
  }
}
