import { Outlet, useParams } from 'react-router-dom'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Icon, RoutingTabs, Spinner, Tab, Text } from 'src/components/ui'
import { useNavigationContext } from 'src/contexts/navigation'
import { useGateway } from 'gateway/api'
import { ErrorDisplay } from 'pages/app'
import { OpcUaConnectionMenu, OpcUaNavigation } from './opc-ua-connection'
import { useOpcSyncTasks } from './opc-ua-connection/opc-ua-connection.api'

export function OpcUaConnectionPage(): JSX.Element {
  const { orgId, siteId, gatewayId, connectionId } = useParams()
  if (!orgId || !siteId || !gatewayId) throw new Error('Parameters missing')

  const { dataSubscriptions, opcUaHierarchy } = useFlags()
  const gatewayQuery = useGateway(siteId, gatewayId)
  const opcUaSyncTasksQuery = useOpcSyncTasks(
    connectionId ?? '',
    !!connectionId && !!opcUaHierarchy,
  )

  const { setTitleComponent } = useNavigationContext()
  useEffect(() => {
    setTitleComponent(
      <OpcUaNavigation
        orgId={orgId}
        siteId={siteId}
        gatewayId={gatewayId}
        opcConnectionId={connectionId}
      />,
    )
    return () => setTitleComponent(null)
  }, [connectionId, gatewayId, orgId, setTitleComponent, siteId])

  if (
    gatewayQuery.isLoading ||
    (opcUaSyncTasksQuery.isLoading && !!connectionId && opcUaHierarchy)
  )
    return <Spinner />
  if (gatewayQuery.isError || (opcUaSyncTasksQuery.isError && opcUaHierarchy)) {
    const errorQuery = gatewayQuery.isError ? gatewayQuery : opcUaSyncTasksQuery
    return (
      <ErrorDisplay
        error={errorQuery.error}
        message="Failed to fetch data"
        action={errorQuery.refetch}
      />
    )
  }

  const connection = gatewayQuery.data.opcConnections?.items?.find(
    c => c?.id === connectionId,
  )

  const opcConnectionTabs: Tab[] = [
    {
      title: 'OPC-UA Details',
      path: '',
      disabled:
        !connectionId || !(connection?.configurationState === 'COMPLETE'),
    },
    {
      title: 'Configuration',
      path: 'configuration',
      disabled:
        !!connectionId &&
        connection !== undefined &&
        connection !== null &&
        connection.configurationState === 'COMPLETE',
    },
  ]

  if (dataSubscriptions) {
    opcConnectionTabs.splice(1, 0, {
      title: 'Subscriptions',
      path: 'subscriptions',
      disabled:
        !connectionId || !(connection?.configurationState === 'COMPLETE'),
    })
  }

  if (opcUaHierarchy) {
    opcConnectionTabs.splice(opcConnectionTabs.length - 1, 0, {
      title: 'Hierarchy',
      path: 'hierarchy',
      disabled:
        !connectionId ||
        !(connection?.configurationState === 'COMPLETE') ||
        // TODO: Remove conditional chaining when feature flag is endabled
        !opcUaSyncTasksQuery.data?.lastHierarchySyncTask,
      tooltipText: !opcUaSyncTasksQuery.data?.lastHierarchySyncTask
        ? 'The OPC-UA hierarchy synchronization has not yet run.'
        : undefined,
    })
  }

  return (
    <div className="h-full p-[1em]">
      <div className="flex h-full flex-col gap-s">
        <div className="flex items-center justify-between rounded-2xs bg-background px-s py-m">
          <div className="flex items-center">
            <Icon
              icon={light('cloud-arrow-up')}
              className="mx-s size-[40px] text-text-tertiary"
            />
            <div className="ml-s flex flex-col gap-2xs">
              <Text variant="description">Connection Name</Text>
              <Text
                bold
                className={classNames(!connection && 'text-text-tertiary')}
              >
                {connection?.name || connection?.id || 'Not specified'}
              </Text>
            </div>
            <div className="ml-xl flex flex-col gap-2xs">
              <Text variant="description">Status</Text>
              <Text
                bold
                className={classNames(
                  !(connection?.configurationState === 'COMPLETE') &&
                    'text-text-tertiary',
                )}
              >
                {connection?.configurationState === 'COMPLETE'
                  ? 'Configured'
                  : 'Not Configured'}
              </Text>
            </div>
            <div className="ml-xl flex flex-col gap-2xs">
              <Text variant="description">Gateway</Text>
              <Text bold>
                <span>{gatewayQuery.data.id || 'Not specified'}</span>
                <span className="font-normal">
                  {gatewayQuery.data.hostname &&
                    ` (${gatewayQuery.data.hostname})`}
                </span>
              </Text>
            </div>
          </div>
          {connection && (
            <OpcUaConnectionMenu
              configurationState={connection.configurationState}
              connectionId={connection.id}
            />
          )}
        </div>
        <div className="flex flex-col gap-s rounded-2xs bg-background p-s">
          <RoutingTabs tabs={opcConnectionTabs} />
          <Outlet />
        </div>
      </div>
    </div>
  )
}
