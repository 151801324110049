import { useFlags } from 'launchdarkly-react-client-sdk'
import { MultipleSelectInput, Option } from 'src/components/ui'
import { ANOMALY_STATUS } from 'src/utility/constants/AnomalyConstFinal'

type Props = {
  statusFilter: string[]
  setStatusFilter: (value: string[]) => void
}
export function AnomalyStatusFilter({
  statusFilter,
  setStatusFilter,
}: Props): JSX.Element {
  const { anomalyDashboard } = useFlags()
  const handleOnChange = (value: Option): void => {
    if (statusFilter.includes(value.value)) {
      setStatusFilter(statusFilter.filter(item => item !== value.value))
    } else {
      setStatusFilter([...statusFilter, value.value])
    }
  }

  const statusesToUse = ANOMALY_STATUS.map(status => {
    if (!anomalyDashboard && status.value === 'NEW') {
      return { ...status, label: 'New' }
    }
    return status
  })

  return (
    <div className="w-[300px]">
      <MultipleSelectInput
        value={
          statusFilter
            ? statusesToUse.filter(({ value }) => statusFilter.includes(value))
            : statusesToUse
        }
        options={statusesToUse}
        onChange={handleOnChange}
        placeholder="Select status..."
      />
    </div>
  )
}
