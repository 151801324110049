import Highlighter from 'react-highlight-words'
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { humanDateTime, timeAgo } from 'src/utility/time'
import { ModelState } from 'src/pages/site/models'
import {
  Icon,
  Text,
  TableConfig,
  getBaseFilterParams,
  Tooltip,
} from 'src/components/ui'
import { TableModel } from 'models/model.types'
import { getModelSubType, label } from 'models/model/model.utils'

type Props = {
  onStarClick?: (id: string) => void
  filter?: string
}

export const getModelTableConfig = ({
  onStarClick,
  filter = '',
}: Props = {}): TableConfig<TableModel> => {
  return {
    allowPaging: true,
    pageSize: 10,
    allowSorting: true,
    allowSearch: false,
    columns: [
      {
        colId: 'actions',
        hide: !onStarClick,
        field: 'viewerHasStarred',
        menuTabs: [],
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        headerName: '',
        sortable: false,
        maxWidth: 50,
        minWidth: 50,
        cellRenderer: ({ data: { viewerHasStarred, id } }: any) => {
          return (
            <Tooltip
              direction="right"
              isOpen={!onStarClick ? false : undefined}
              render={() => (
                <Text>
                  {viewerHasStarred
                    ? 'Remove from Favorite'
                    : 'Add to Favorite'}
                </Text>
              )}
            >
              <Icon
                icon={
                  viewerHasStarred ? solid('star-sharp') : light('star-sharp')
                }
                className={classNames(
                  'mb-3xs',
                  viewerHasStarred && 'text-[orange]',
                )}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  onStarClick?.(id)
                }}
              />
            </Tooltip>
          )
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 100,
        initialFlex: 400,
        filterParams: getBaseFilterParams(true),
        cellRenderer: ({ data: { name, link } }: any) => {
          return (
            <Link
              to={link}
              className="inline-block size-full text-inherit no-underline"
            >
              <Text bold className="inline">
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={name}
                  autoEscape
                />
              </Text>
            </Link>
          )
        },
      },
      {
        field: 'type.name',
        headerName: 'Type',
        initialFlex: 200,
        minWidth: 100,
        cellRenderer: ({
          data: {
            forecastHorizon,
            type: { name },
            method,
          },
        }: any) => {
          const subType = getModelSubType(method.name, forecastHorizon)
          return (
            <>
              <Text>
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={name}
                  autoEscape
                />
              </Text>
              <Text className="text-text-tertiary">
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={subType}
                  autoEscape
                />
              </Text>
            </>
          )
        },
      },
      {
        field: 'tagDisplayName',
        headerName: 'Output Tag',
        initialFlex: 200,
        minWidth: 130,
        filterParams: getBaseFilterParams(true),
        cellRenderer: ({
          data: {
            tagDisplayName,
            tag: { description, engUnit },
          },
        }: any) => {
          return (
            <>
              <Text>
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={tagDisplayName}
                  autoEscape
                />
              </Text>
              <Text className="text-text-tertiary">
                <Highlighter
                  searchWords={filter.split(' ')}
                  textToHighlight={
                    engUnit && engUnit.toLowerCase() !== 'none'
                      ? `${description} (${engUnit})`
                      : description
                  }
                  autoEscape
                />
              </Text>
            </>
          )
        },
      },
      {
        field: 'created',
        headerName: 'Created',
        initialFlex: 200,
        minWidth: 120,
        filter: '',
        cellRenderer: ({ value }: any) => (
          <>
            <div>
              <Text>{humanDateTime(value)}</Text>
            </div>
            <Text className="text-text-tertiary">{timeAgo(value)}</Text>
          </>
        ),
      },
      {
        field: 'state',
        headerName: 'State',
        minWidth: 150,
        initialFlex: 200,
        // Format filter options to human readable labels
        filterValueGetter: ({ getValue }) => {
          return label(getValue('state'))
        },
        cellRenderer: ({ data: { state } }: any) => {
          return (
            <ModelState state={state} className="w-full">
              <Highlighter
                searchWords={filter.split(' ')}
                textToHighlight={label(state) || ''}
                autoEscape
              />
            </ModelState>
          )
        },
      },
    ],
  }
}
