import { themeAlpine } from 'ag-grid-community'

export const tableTheme = themeAlpine.withParams({
  accentColor: '#00DC00',
  menuBackgroundColor: '#FFFFFF',
  oddRowBackgroundColor: '#FFFFFF',
  selectedRowBackgroundColor: '#CCF8CC88',
  borderColor: '#E0E0E0',
  headerBackgroundColor: '#FFFFFF',
})
