import { Link } from 'react-router-dom'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon, Text, Tooltip } from 'src/components/ui'
import { tagLabelToText } from 'src/services'
import { Tag } from 'src/types'
import { getBaseFilterParams } from '../table.utils'
import { TableConfig } from '../table.types'

type Props = {
  canDrag: boolean
  rootLink: string
  canEdit: boolean
  onEditTag: (tag: Tag) => void
}
export const getAssetTagsTableConfig = ({
  canDrag,
  rootLink,
  canEdit,
  onEditTag,
}: Props): TableConfig => {
  const config: TableConfig = {
    pageSize: 25,
    allowPaging: true,
    allowSorting: false,
    pageSizes: [5, 10, 25, 100],
    disallowSelection: false,
    columns: [
      {
        headerName: 'Tag',
        dndSource: canDrag,
        filterParams: getBaseFilterParams(true),
        valueGetter: ({ data: { displayName, tagName } }: any) =>
          displayName ?? tagName,
        initialFlex: 140,
        cellRenderer: ({ value, data }: any) => (
          <Link
            className="text-text-brand no-underline"
            to={`${rootLink}/tags/${data.tagNodeId}`}
          >
            <Text bold>{value}</Text>
          </Link>
        ),
      },
      {
        field: 'parentName',
        headerName: 'Parent Asset',
        initialFlex: 140,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'label',
        headerName: 'Label',
        initialFlex: 140,
        filterValueGetter: ({ data }: any) => {
          if (data.label) return tagLabelToText(data.label)
          return ''
        },
        cellRenderer: ({ value }: any) => (
          <Text>{value ? tagLabelToText(value) : ''}</Text>
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        filterParams: getBaseFilterParams(true),
        initialFlex: 140,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'engUnit',
        headerName: 'Unit',
        initialFlex: 120,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
    ],
  }
  if (canEdit) {
    config.columns.push({
      headerName: '',
      sortable: false,
      filter: '',
      initialFlex: 50,
      suppressHeaderMenuButton: true,
      cellRenderer: ({ data }: any) => (
        <Tooltip render={() => <Text>Edit Tag</Text>}>
          <Icon
            onClick={() => onEditTag(data)}
            icon={light('pen-circle')}
            size="big"
            className="cursor-pointer"
          />
        </Tooltip>
      ),
    })
  }
  return config
}
